export async function getToken(action) {
    return new Promise((resolve) => {
        window.grecaptcha.enterprise.ready(async () => {
            const token = await window.grecaptcha.enterprise.execute('6LdTw8kqAAAAAM0uvJOr50pshnCKoZtWBIBmEpPw', {action});
            resolve(token);
        });
    });
}

export function loadRecaptcha() {
    return new Promise((resolve, reject) => {
      if (window.grecaptcha) {
        // Já carregado
        return resolve(window.grecaptcha)
      }
  
      const script = document.createElement("script")
      script.src = "https://www.google.com/recaptcha/enterprise.js?render=6LdTw8kqAAAAAM0uvJOr50pshnCKoZtWBIBmEpPw"
      script.async = true
      script.defer = true
  
      script.onload = () => resolve(window.grecaptcha)
      script.onerror = () => reject(new Error("Failed to load reCAPTCHA"))
  
      document.head.appendChild(script)
    })
  }